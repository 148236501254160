@import 'includes/font-awesome';



// Vars


$main-color-1: #0b8083;
$main-color-2: #12ccb6;
$btn-main-color: gold;
$btn-hover-color: rgb(252, 220, 124);
$bewertung-bg: #ededed;
$logo: url(../img/logo.png);



@import "font-awesome.css";


.title {
  text-align: center;
  padding: 10px 20px;
  color: white;
  font-weight: bold;
  font-size: 1.1rem; }
.fb {
  background: #41559F; }
.goo {
  background: #2585FC; }
.yel {
  background: #DF0219; }



i {
 margin-right: 10px; }


* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  text-decoration: none;
  -webkit-font-smoothing: antialiased; }



h1 {
  font-weight: 700;
  font-size: 2.7rem;
  line-height: 1.3;
  margin-bottom: 30px; }


h3 {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 1.3;
  margin-bottom: 30px;
  color: $main-color-1; }


h4 {
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 30px;
  letter-spacing: 0;
  line-height: 1.2; }


p, a {
  font-size: 1rem;
  line-height: 1.6; }


.main-wrapper {
  width: 100%;
  position: relative; }


.header {
  width: 100%;
  height: 40em;
  top: 80px;
  background: linear-gradient($main-color-2, $main-color-1);
  position: relative;
  .star {
    width: 50%;
    height: 500px;
    background: {
      image: url(../img/sterne.png);
      repeat: no-repeat;
      size: contain;
      position: center; }
    position: absolute;
    right: 0;
    top: 43%;
    transform: translateY(-50%);
    z-index: 0; }
  .headline {
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
    top: 42%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: relative;
    left: 5%;
    z-index: 1;
    h2 {
      color: white;
      font-weight: 300;
      font-size: 1.6rem;
      max-width: 70%; }
    h1 {
      text-transform: uppercase;
      letter-spacing: 0.02rem;
      color: white;
      max-width: 70%; } } }
nav {
  position: fixed;
  z-index: 99999999;
  width: 100%;
  .logo {
    background: {
      color: transparent;
      image: $logo;
      size: contain;
      repeat: no-repeat;
      position: center; }
    width: 130px;
    height: 130px;
    top: 0;
    position: absolute;
    z-index: 9999999;
    left: 5%;
    box-shadow: 0 0 5px rgba(0,0,0,0.15), 1px 1px 15px rgba(0,0,0,0.2); }

  .navbar {
    position: absolute;
    width: 100%;
    background: rgba($main-color-1, 0.9);
    height: 80px;
    left: 0px;
    z-index: 0;
    top: 0; } }






.content-wrapper {
  position: relative;
  background: #fff;
  z-index: 5; }



.content {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 4vw 5%;
  z-index: 100;
  position: relative; }


.btn {
  position: relative;
  background: $btn-main-color;
  padding: 10px 25px;
  display: inline-block;
  font-weight: 600;
  color: #333;
  border-radius: 2px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  &:hover {
    background: lighten($btn-main-color, 10%);
    -webkit-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2); } }


.grau {
  background: $bewertung-bg;
  position: relative;
  .content {
    padding: 80px 5%;
    h4 {
      color: $main-color-1;
      text-align: center;
      text-transform: uppercase; } } }


.bubble {
  position: absolute;
  left: 50%;
  top: 0;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  border-top: 30px solid white;
  border-left: 30px solid #efefef;
  border-right: 30px solid #efefef; }


#danke {
  .flex-wrapper {
    align-items: center; } }
.flex-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  .col-1 {
    flex: 5;
    padding-right: 60px;
    min-width: 300px; }

  .col-2 {
    flex: 2;
    background: #efefef;
    padding: 20px;
    position: relative;
    min-width: 300px;
    p {
      margin-bottom: 15px; }
    #img-sterntaler {
      width: calc(100% + 40px);
      top: -20px;
      height: 200px;
      left: -20px;
      background: {
        image: url(../img/sterntaler.png), linear-gradient($main-color-2, $main-color-1);
        size: contain;
        position: center;
        repeat: no-repeat; }
      position: relative; }
    h3 {
      font-size: 1.5rem;
      margin-bottom: 15px; } } }


#img-g, #img-fb, #img-y {
  width: 100%;
  height: 200px;
  background: radial-gradient(#666, #333); }


.flex-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 10px;
  min-width: 280px;
  box-shadow: 0 0 15px rgba(0,0,0,0.15); }


.text-wrap {
  padding: 30px;
  text-align: center;
  p {
    margin-top: 30px;
    color: #666;
    font-weight: 300;
    font-size: 0.925rem;
    text-align: left; } }


.flex-item:first-child {
  margin: 10px 10px 10px 0;
  &:last-child {
    margin-left: 10px 0 10px 10px; } }


.google {
  background: white;
  #img-g {
    background: {
      image:url(../img/google.png) {}
      size: cover;
      position: top center; } }
  .btn {
    background: #2585FC;
    color: white;
    font-weight: 400;
    &:hover {
      background: lighten(#2585FC, 5%); } } }




.facebook {
  background: white;
  #img-fb {
    background: {
      image:url(../img/facebook.png) {}
      size: cover;
      position: top center; } }
  .btn {
    background: #41559F;
    color: white;
    font-weight: 400;
    &:hover {
      background: lighten(#41559F, 5%); } } }


.yelp {
  background: white;
  #img-y {
    background: {
      image:url(../img/yelp.png) {}
      size: cover;
      position: top center; } }
  .btn {
    background: #DF0219;
    color: white;
    font-weight: 400;
    &:hover {
      background: lighten(#DF0219, 5%); } } }


@media (max-width:768px) {
  .flex-wrapper {
    display: block;
    .flex-item {
      margin: 0 0 20px; }
    .col-1 {
      padding-right: 0px!important;
      width: 100%;
      margin-bottom: 20px;
      margin-top: 20px;
      h3 {
        font-size: 2em; } } }

  .header {
    height: 32em;
    .headline {
      width: 90%;
      h2 {
        font-size: 1.2rem;
        margin-bottom: 10px; }
      h1 {
        font-size: 2rem; } } } }
